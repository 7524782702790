import React from "react";

import "./button.scss";

const Button = ({
    disabled= false,
    type="primary",
    content = <></>,
    className="",
    ...props
}) => {
    return (
      <button
        {...props}
        disabled={disabled}
        className={`btn button-em ${
          disabled ? "" : "btn-primary "
        } ${type} ${className}`}
      >
        {content}
      </button>
    );
}

export default Button;