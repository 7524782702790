let activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development"
let productionP = activeEnv === "production";

module.exports = {
  siteTitle: "Florbela Castilho Manso - Psicologia", // <title>
  description:
    "Psicologia da infância e adolescência \n Psicologia de adultos Avaliação psicológica \n Orientação vocacional \n Acompanhamento psicoterapêutico",
  logo: "src/assets/img/logo.png",
  manifestName: productionP ? "" : "",
  manifestShortName: "", // max 12 characters
  manifestStartUrl: productionP ? "" : "",
  manifestBackgroundColor: "#663399",
  manifestThemeColor: "#663399",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/icon.png",
  pathPrefix: "/",
  url: productionP
    ? "https://www.florbelacastilhomanso.pt/"
    : "http://127.0.0.1:8888",
  keywords:
    "Psicologia Clínica em Lisboa, Psicologia clínica, Florbela Castilho Manso, psicóloga, psicóloga para crianças, consultas em Lisboa, Lisboa, Florbela Castilho Manso,Comportamentos compulsivos, Divórcio, Burnout, Stress, Luto, Depressão, Ansiedade, Dificuldade nas Relações Interpessoais, Alterações do Comportamento Social, Comportamentos auto-lesivos, Relações Problemáticas com Pais, Família e Amigos, Isolamento Social, Baixa Auto-estima, Abuso de Substâncias, Baixo Rendimento Escolar, Obsessões ou Fobias, Recusa Repentina em Ir à Escola, Ansiedade , Tristeza, Separação dos Pais, Hiperatividade, Problemas no Comportamento, apatia, agressividade, dificuldades de relacionamento, Baixo Rendimento Escolar, insucesso na escola, más notas, desmotivação, problemas de concentração e atenção",
  cta: "Marcar Consulta",
  emailSignature: "Florbela Castilho Manso",
  emailAddr: "geral@florbelacastilhomanso.pt",
  tel: "+351964085990",
  // social
  socialLinks: [
    {
      icon: ["fas", "envelope"],
      name: "Email",
      url: "mailto:geral@florbelacastilhomanso.pt",
    },
    {
      icon: ["fas", "phone"],
      title: "Phone",
      url: "tel:+351964085990",
    },
  ],
};
