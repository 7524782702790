import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStaticQuery, graphql } from "gatsby";
import Imgage from "./src/components/Image";

import {
  faMale,
  faChild,
  faUserGraduate,
  faBrain,
  faBriefcase,
  faUserFriends,
  faArrowAltCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";



library.add(
  faChild,
  faMale,
  faUserGraduate,
  faBrain,
  faBriefcase,
  faArrowAltCircleRight,
  faUserFriends
);

const { cta } = require("./config");



const duration = {
  duration: 60,
  firstduration: 60
};
const icon = (
  <FontAwesomeIcon
    className={"primary-text-color mr-1"}
    icon={"arrow-alt-circle-right"}
  />
);
const timetable = [
  {
    day: "segunda-feira",
    starttime: "9:00",
    endtime: "20:00",
    ...duration,
  },
  {
    day: "terça-feira",
    starttime: "9:00",
    endtime: "20:00",
    ...duration,
  },
  {
    day: "quarta-feira",
    starttime: "9:00",
    endtime: "20:00",
    ...duration,
  },
  {
    day: "quinta-feira",
    starttime: "9:00",
    endtime: "20:00",
    ...duration,
  },
  {
    day: "sexta-feira",
    starttime: "9:00",
    endtime: "20:00",
    ...duration,
  },
  {
    day: "sábado",
    starttime: "9:00",
    endtime: "20:00",
    ...duration,
  },
 
];
const timetableDesc = <><p>Segunda a Sábado</p><p>9:00 às 20:00</p></>;
const data = {
  bookAppointment: {
    title: "Agende a sua consulta comigo",
    timetable,
    subtitle: "",
    bookFor: "Florbela Castilho Manso",
    holidays: [],
    notificationsemail: "geral@florbelacastilhomanso.pt",
    form: "",
  },
  BG: "FG",
  sections: [
    {
      component: "Hero",
      content: [
        {
          title: "Florbela Castilho Manso",
          subtitle: "Psicologia Clínica",
          image: "hero1.jpg",
          cta: cta,
          backgroundPosition: "left center",
        },
        {
          title: "Psicologia da infância e adolescência",
          subtitle: (
            <i>
              "As crianças são o recurso mais importante do mundo e a melhor
              esperança para o futuro"
              <small>Juahrez Alves</small>
            </i>
          ),
          notes:
            "Recusa Repentina em Ir à Escola ou baixo rendimento escolar? Hiperatividade, ansiedade ou tristeza? Separação dos Pais? Problemas de Comportamento ou abuso de Substâncias? Relações Problemáticas ou isolamento social?",
          image: "hero2.jpg",
          cta: cta,
          backgroundPosition: "left center",
        },
        {
          title: "Psicologia de adultos",
          subtitle: (
            <i>
              "O paradoxo curioso é que quando eu me aceito como eu sou, então
              eu mudo!" <small>Carl Rogers</small>
            </i>
          ),
          notes:
            "Irei ajudar a identificar e compreender os aspectos geradores de sofrimento procurando restabelecer o seu equilíbrio e bem-estar",
          image: "hero3.jpg",
          cta: cta,
        },
        {
          title: "Avaliação psicológica",
          subtitle: (
            <i>
              “Quem olha para fora, sonha, quem olha para dentro, desperta”
              <small>Carl Jung</small>
            </i>
          ),
          notes:
            "Ajuda a compreender o seu funcionamento, pretendendo identificar estratégias de intervenção para aumentar o seu  bem-estar",
          image: "hero4.jpg",
          cta: cta,
          backgroundPosition: "left center",
        },
        {
          title: "Orientação vocacional",
          notes:
            "Necessita de tomar decisões para escolher a área do ensino secundário, curso ou profissão? Eu posso ajudar!",
          subtitle: (
            <i>
              “Conhece-te a ti mesmo e torna-te quem tu és”{" "}
              <small>Nietzsche</small>
            </i>
          ),
          image: "hero5.jpg",
          cta: cta,
        },
        {
          title: "Acompanhamento psicoterapêutico",
          subtitle: (
            <i>
              "Quando a dor de não estar vivendo for maior do que o medo da
              mudança, a pessoa muda" <small>Sigmund Freud</small>
            </i>
          ),
          notes:
            "Processo de transformação, de desenvolvimento pessoal e crescimento emocional, constituindo-se igualmente como um instrumento que facilita o auto-conhecimento",
          image: "hero6.jpg",
          cta: cta,
        },
      ],
    },
    {
      name: "Sobre",
      component: "About",
      description: "Sobre mim",
      content: {
        title: "Florbela Castilho Manso",
        subtitle: (
          <>
            <div>Licenciatura em Ciências Psicológicas - ISPA</div>
            <div>Mestrado em Psicologia Clínica - ISPA</div>
            <div>Membro efetivo Ordem Psicólogos Portugueses</div>
            <div>Cédula profissional n°26362</div>
            <div>
              Certificado Psicopatologia do Adolescente - Avaliação, Diagnóstico
              e Intervenção
            </div>
            <div>Formadora Certificada - IEFP</div>
            <div>Certificado OPP - "Intervenção em crianças e jovens em risco"
            </div><div>
            Certificado OPP - "Intervenção psicológica problemas ligados ao álcool -IPPLA
</div><div>
            Certificado OPP - "Intervenção psicológica em pessoas LGBTQ
</div><div>
            Curso Care Team - equipa de voluntários / auxílio em situações de crise
</div><div>
            Certificado IEFP - Socorrismo básico
            </div>
          </>
        ),
        image: "hero1.jpg",
        description: (
          <>
            <p>
              A paixão pela Psicologia!!! Considero-a um grande desafio pessoal
              e profissional diário, com o objetivo de melhoria contínua. A
              formação académica e as competências técnicas do Psicólogo são
              essenciais nesta profissão, no entanto, considero que a escuta
              ativa, a capacidade de criar relação empática com o paciente, o
              não julgamento e o respeito por cada paciente, vai contribuir para
              fortes ganhos terapêuticos.
            </p>
            <p>
              A minha participação como Psicóloga num processo de crescimento do
              paciente é algo de imensa responsabilidade, exigindo de mim um
              desenvolvimento pessoal diário, onde pretendo construir uma
              relação terapêutica empática, implementar técnicas de avaliação e
              de intervenção e adaptá-las a cada caso, respeitando sempre os
              princípios éticos da Psicologia.
            </p>
            <p>
              A faixa etária das crianças e adolescentes tem tido um papel
              primordial no meu percurso profissional na área da Psicologia. A
              minha experiência nesta área iniciou-se no meu estágio curricular
              na Santa Casa da Misericórdia de Lisboa, área de Pedo-Psiquiatria
              , tendo como objectivo a avaliação e intervenção psicológica de
              crianças e jovens adolescentes.
            </p>
            <p>
              Integrei uma equipa multidisciplinar (Psicólogos, Terapeutas da
              Fala, Pediatras, Pedo-psiquiátras, entre outros), onde as áreas de
              atuação da infância e adolescência são diversas, podendo o foco
              incidir na prevenção, deteção e intervenção em dificuldades
              emocionais/sociais, humor, ansiedade, problemas de comportamento,
              luto, dificuldades de aprendizagem, entre outras.
            </p>
            <p>
              As consultas de Psicologia são em consultório privado, na área da
              grande Lisboa e Póvoa de Santa Iria,e a minha actividade prende-se
              sobretudo com avaliações psicológicas, entrevistas de avaliação e
              orientação vocacional e apoio psicológico.
              <div className="mt-2 mb-2 ">
                <span className="destak">O que esperar de mim?</span>
              </div>
              <div>
                {[
                  "Resiliência e empenho",
                  "Seriedade e responsabilidade",
                  "Ética profissional",
                  "Formação contínua",
                  "Trabalho em equipa",
                ].map((v, i) => (
                  <div className="pl-2" key={`adt-${i}`}>
                    {icon}
                    {v}
                  </div>
                ))}
              </div>
            </p>
            <p>
              O meu trabalho é pautado pela ética e deontologia profissional de
              acordo com o Código dos Psicólogos Portugueses, que preserva a
              confidencialidade dos pacientes e obriga ao respeito da autonomia
              individual.
            </p>
            <p>
              Pretendo estabelecer um compromisso diário com os meus pacientes,
              desenvolvendo o meu trabalho com base numa relação de confiança,
              seriedade e responsabilidade.
            </p>
          </>
        ),
      },
    },
    {
      name: "Serviços",
      component: "ServicesEMMIM",
      description: "Como o poderei ajudar?",
      content: {
        mode: "row",
        title: "Serviços",
        image: "hero1.jpg",
        description: "O que posso fazer por si?",
        elements: [
          {
            image: "hero2.jpg",
            title: "Psicologia da criança",
            description: (
              <>
                Na consulta com a criança, o principal objectivo é o trabalho em
                conjunto com a criança e respetivos pais, de forma a promover
                práticas permitindo que o seu desenvolvimento e as suas aptidões
                sejam estimuladas. O desenvolvimento emocional, social,
                cognitivo e comportamental de uma criança é essencial para a sua
                formação para que se torne um adulto saudável no futuro, sendo
                que, os seus primeiros anos de vida são determinantes na
                definição da sua personalidade. No dia-a-dia de uma criança,
                existem determinados comportamentos que detetados precocemente,
                podem ser facilmente ultrapassados . Actividades lúdicas como o
                desenho, pintura e jogos infantis, permitem que a criança se
                questione e descubra que pode ser escutada e compreendida,
                permitindo que a mesma obtenha respostas para as questões que
                enfrenta. Na 1ª consulta é realizada uma entrevista clínica com
                os pais da criança e com a criança, onde serão definidos
                objectivos para o processo terapêutico a desenvolver, sendo que
                o mesmo termina, quando os objectivos terapêuticos forem
                atingidos.
                <div className="mt-2 mb-2 text-left">
                  <span className=" destak">
                    Principais Áreas de Intervenção:
                  </span>
                </div>
                <div className="text-left">
                  <div className="pl-2">
                    {icon}Recusa Repentina em Ir à Escola
                  </div>
                  <div className="pl-2">{icon}Ansiedade ou Tristeza</div>
                  <div className="pl-2">{icon}Separação dos Pais</div>
                  <div className="pl-2">{icon}Hiperatividade</div>
                  <div className="pl-2">
                    {icon}
                    Problemas no Comportamento (apatia, agressividade,
                    dificuldades de relacionamento)
                  </div>
                  <div className="pl-2">
                    {icon}
                    Baixo Rendimento Escolar (insucesso, desmotivação, problemas
                    de concentração e atenção)
                  </div>
                </div>
              </>
            ),
          },
          {
            icon: "user-graduate",
            title: "Psicologia do adolescente",
            image: "hero7.png",
            description: (
              <>
                O período da adolescência é a transição da infância para a idade
                adulta, é uma fase de mudanças físicas, psicológicas e sociais,
                o corpo muda e as ideias também, cada adolescente tem uma
                experiência única de vida. Na consulta com o Adolescente, a
                autonomia, transformações no corpo, início da sexualidade a
                procura da própria identidade são as questões mais frequentes.
                Torna-se necessário promover estratégias de mudança por forma a
                desenvolver competências que permitam ao adolescente lidar com
                estas questões. Na primeira consulta, caso o adolescente tenha
                idade inferior a 16 anos, é necessário que se faça acompanhar
                pelos pais ou por um adulto responsável.Estrita
                confidencialidade em tudo o que é partilhado na sessão.
                <br />
                <div className="mt-2 mb-2 text-left">
                  <span className=" destak">
                    Principais Áreas de Intervenção:
                  </span>
                </div>
                <div className="text-left">
                  <div className="pl-2">{icon}Comportamentos auto-lesivos</div>
                  <div className="pl-2">
                    {icon}Relações Problemáticas com Pais, Família e Amigos
                  </div>
                  <div className="pl-2">{icon}Isolamento Social</div>
                  <div className="pl-2">{icon}Baixa Auto-estima</div>
                  <div className="pl-2">{icon}Abuso de Substâncias</div>
                  <div className="pl-2">{icon}Baixo Rendimento Escolar</div>
                  <div className="pl-2">{icon}Obsessões ou Fobias</div>
                </div>
              </>
            ),
          },
          {
            image: "hero3.jpg",
            title: "Psicologia de adultos",
            description: (
              <>
                A psicoterapia do adulto procura ajudar o paciente a identificar
                e compreender os aspectos geradores de sofrimento procurando
                restabelecer o seu equilíbrio e bem-estar, desenvolvendo novas
                capacidades para lidar com as vicissitudes da vida. Na vida
                adulta, pode surgir a necessidade do adulto necessitar do seu
                tempo de introspeção e querer interagir com uma pessoa neutra
                sobre os seus sentimentos, as suas angústias, os seus medos,
                levando-o a sentir necessidade de recorrer a uma ajuda técnica
                especializada. As consultas de Psicologia e o acompanhamento
                psicológico vão conceber um ponto de equilíbrio entre as suas
                emoções, pensamentos e comportamentos promovendo atitudes que
                vão potenciar uma melhor auto-estima, o bem-estar e por
                consequência uma melhoria de qualidade de vida.
                <br />
                <div className="mt-2 mb-2 text-left">
                  <span className=" destak">
                    Principais Áreas de Intervenção:
                  </span>
                </div>
                <div className="text-left">
                  {[
                    "Comportamentos compulsivos",
                    "Divórcio",
                    "Burnout",
                    "Stress",
                    "Luto",
                    "Depressão",
                    "Ansiedade",
                    "Dificuldade nas Relações Interpessoais",
                    "Alterações do Comportamento Social",
                  ].map((v, i) => (
                    <div className="pl-2" key={`adt-${i}`}>
                      {icon}
                      {v}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            image: "hero4.jpg",
            title: "Avaliação psicológica",
            description:
              "É um método científico de observação e análise do funcionamento psicológico, num espaço de tempo limitado, com o objectivo de responder a um pedido de avaliação. Consiste num pequeno número de consultas, com realização de entrevistas e aplicação de testes e provas psicológicas, e no final, a elaboração de um relatório de avaliação. No final o psicólogo estará apto a fornecer um diagnóstico psicológico da situação e, desse modo também, a traçar um plano de intervenção terapêutica para um eventual tratamento.",
          },
        ],
      },
    },
    {
      name: "Horário",
      component: "Timetable",
      description: "Quais os serviços disponíveis?",
      content: {
        cta: cta,
        title: "Horário de atendimento",
        timetable,
        timetableDesc,
        description: (
          <span>
            <div>
              As sessões têm a duração de 50 minutos e são{" "}
              <span className="destak">obrigatoriamente confidenciais.</span>
            </div>
          </span>
        ),
      },
    },
    {
      name: "Testemunhos",
      component: "Reviews",
      description: "Quais os serviços disponíveis?",
      content: {
        title: "O que dizem de mim?",
        subtitle: "Conheça a opinião de quem me conhece melhor",
        elements: [
          {
            name: "Ana R.",
            description:
              "Elevadas competências afectivas e relacionais para com as crianças e respectivas família",
          },
          {
            name: "Mãe da Bárbara",
            description:
              "O meu agradecimento pelas suas orientações e as dicas que me deu contribuíram para ajudar a minha filha. (…) A sua ansiedade diminuiu e neste momento, está uma criança mais tranquilo e confiante. (…) mãe da Bárbara",
          },
          {
            name: "",
            description:
              "A empatia, assertivade, profissionalismo e orientação com que conduziu cada sessão, de uma forma muito leve e simples, permitiu-me construir ferramentas para ultrapassar situações que me afectavam diariamente.",
          },
        ],
      },
    },
    {
      component: "Cta",
      content: {
        cta: cta,
        noTimetable: true,
        title: "Como poderei ajudar?",
        description:
          "A minha abordagem terapêutica é individualizada, onde o respeito e a singularidade de cada paciente são primordiais.",
      },
    },
    {
      name: "Contactos",
      component: "ContactUs",
      content: {
        title: "Como poderei ser contactado?",
        formLabel: "Diga-me olá",
        //formUrl: "https://ddfqqwdwcmmgtkj.form.io/contactformsite/submission",
        phoneNr: "+351 964 085 990",
        //whatsapp: "351 91X XXX XXX",
        email: "geral@florbelacastilhomanso.pt",
        addressLabel: "Onde me encontrar?",
        address: [<>
            <span className = "contact-title" >
              Lisboa
            </span>
            <div className = "mt-0 contact-title" >
              Póvoa Santa Iria
            </div>
        </>]
          
        ,
      },
    },
  ],
};

export default data;