import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import config from "../../../config";
import "moment/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/pt"; // the locale you want
registerLocale("el", el); 

moment.locale("pt");

library.add(...[faCheck]);
const sd = new Date();
sd.setDate(sd.getDate());
const ed = new Date();
ed.setMonth(ed.getMonth() + 4);
const days = [
  "domingo",
  "segunda-feira",
  "terça-feira",
  "quarta-feira",
  "quinta-feira",
  "sexta-feira",
  "sábado",
];
const __DATE__FORMAT = new Date(2020, 11, 31)
  .toLocaleDateString()
  .replace("31", "DD")
  .replace("12", "MM")
  .replace("2020", "YYYY");

const getWeekday = (val) => {
  let v = val.split(/\/|-/g);
  let format = __DATE__FORMAT.split(/\/|-/g);
  return new Date(
    v[format.indexOf("YYYY")],
    parseInt(v[format.indexOf("MM")]) - 1,
    v[format.indexOf("DD")]
  ).getDay();
};
var parseIn = (date_time) => {
  var d = new Date();
  d.setHours(date_time.split(":")[0]);
  d.setMinutes(date_time.split(":")[1]);

  return d;
};

//make list
var getTimeIntervals = (time1, time2, duration) => {
  var arr = [];
  while (time1 < time2) {
    arr.push(`${time1.getHours()}:${time1.getMinutes() || "00"}`);
    time1.setMinutes(time1.getMinutes() + duration);
  }
  return arr;
};

const getDaysBetweenDates = (start, end, dayName) => {
  let result = [];

  let day = days.indexOf(dayName.toLowerCase());
  // Copy start date
  let current = new Date(start);
  // Shift to next of required days
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  // While less than end date, add dates to result array
  while (current < end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }
  return result;
};

const _K = "AIzaSyDr1ek9QlcQJa-L4OMzdXCJ-zef0C3MAdA";
const ISODateString = (d) => {
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  return (
    d.getUTCFullYear() +
    "-" +
    pad(d.getUTCMonth() + 1) +
    "-" +
    pad(d.getUTCDate()) +
    "T" +
    pad(d.getUTCHours()) +
    ":" +
    pad(d.getUTCMinutes()) +
    ":" +
    pad(d.getUTCSeconds()) +
    "Z"
  );
};

export default function BookAppointment({
  title = "Vamos Marcar a sua consulta?",
  subtitle = "",
  bookFor,
  calendarid,
  timetable = [],
  holidays = [],
  notificationsemail = "geral@emmim.pt",
  formUrl = "",
  url,
}) {
  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [listFields, setListfields] = useState({
    bookFor,
    firstTime: "no",
    notificationsemail,
    date: new Date(),
  });
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(true);

  const [message, setMessage] = useState({});
  const [events, setEvents] = useState([]);
  const [dates, setDates] = useState({});
  const [hours, setHours] = useState([]);
  const [firstTime, setFirstTime] = useState(false);

  useEffect(() => {
    let slots = {};
    timetable.forEach((t) => {
      let day = days.indexOf(t.day.toLowerCase());
      slots[day] = [
        ...(slots[day] || []),
        ...getTimeIntervals(
          parseIn(t.starttime),
          parseIn(t.endtime),
          firstTime && t.firstduration ? t.firstduration : t.duration
        ),
      ];
    });
    setHours(slots);

    let weekdays = timetable.map((d) => d.day);
    let dates = weekdays.map((w) => getDaysBetweenDates(sd, ed, w));
    let datesObj = {};
    dates
      .flat()
      .sort((d, d2) => new Date(d) - new Date(d2))
      .forEach((v) => {
        if (typeof datesObj[v.toLocaleDateString()] === "undefined") {
          datesObj[v.toLocaleDateString()] = [];
        }
      });
    events.forEach((v) => {
      if (v.start.date) {
        datesObj[new Date(v.start.date).toLocaleDateString()] = undefined;
      } else {
        let dt = new Date(v.start.dateTime).toLocaleDateString();
        if (datesObj[dt]) {
          datesObj[dt].push(v);
        }
      }
    }, []);

    Object.keys(datesObj).forEach((dt) => {
      let dateSlots =
        datesObj[dt] &&
        Object.keys(slots).length > 0 &&
        slots[getWeekday(dt)]
          .map((v) => {
            let bookings = datesObj[dt].map(
              (d) =>
                `${new Date(d.start.dateTime).getHours()}:${
                  new Date(d.start.dateTime).getMinutes() || "00"
                }`
            );
            return datesObj[dt] && bookings.indexOf(v) === -1 && v;
          })
          .filter((e) => e);
      if (dateSlots && dateSlots.length > 0) {
        datesObj[dt] = dateSlots;
      } else {
        datesObj[dt] = undefined;
      }
    });
    setDates(datesObj);
     setListfields({
       ...listFields,
       date: includeDates[0],
     });
  }, [timetable, events, firstTime]);

  useEffect(() => {
    message.variant &&
      setTimeout(() => {
        setMessage({});
      }, 10000);
  }, [message]);
/*
  useEffect(() => {
    if (!calendarid) {
      setEvents([]);
      return;
    }
    fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
        calendarid
      )}/events?key=${_K}&timeMax=${ISODateString(ed)}&timeMin=${ISODateString(
        sd
      )}`,
      {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          //  Origin: url,
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    )
      .then((r) => r.json())
      .then((r) => {
        if (r.error) {
          setRefresh(!refresh);
          return;
        }
        setEvents(r.items);
      })
      .catch((e) => {
        setRefresh(!refresh);
      });

    //GET https://www.googleapis.com/calendar/v3/calendars/calendarid/events
  }, [refresh, calendarid]);
*/
  const submitForm = (e) => {
    setClicked(true);
    e.preventDefault();
    if (document.getElementById("email").value) {
      return;
    }
    let err = {};
    ["date", "hours", "phoneNr", "cEmail", "firstTime", "name"].forEach(
      (v) => {
        if (!listFields[v]) {
          err[v] = true;
        }
      }
    );

    if (Object.keys(err).length > 0) {
      setErrors(err);
      setMessage({
        msg: `Ups... Parece que falta preencher algo. Preencha pff os campos obrigatórios`,
        variant: "error",
      });
      return;
    }
    fetch(`${config.url}${config.pathPrefix}.netlify/functions/eResponse/`, {
      method: "POST",
      body: JSON.stringify({
        ...listFields,
        date: moment(listFields.date, __DATE__FORMAT)
          .locale('pt')
          .format("LL"),
        from: "booking",
      }),
    })
      .then((data) => {
        setSuccess(true);
        setClicked(false);
        setErrors({});
        setListfields({
          bookFor,
        });
        setMessage({
          msg: `Sessão pré-agendada. Iremos entrar em contacto em breve para confirmar a sua consulta :)`,
          variant: "success",
        });
      })
      .catch((i) => {
        setMessage({
          msg: `Não estava à espera disto, mas estas coisas às vezes acontecem :) `,
          variant: "error",
        });
      });
  };

  const handleChange = (e) => {
    setErrors({ ...errors, [e.target.name]: undefined });
    setListfields({
      ...listFields,
      [e.target.name]: e.target.value,
    });
  };
  let includeDates = Object.keys(dates)
    .map(
      (v) =>
        holidays.indexOf(v) === -1 &&
        dates[v] &&
        dates[v].length > 0 &&
        moment(v, __DATE__FORMAT).toDate()
    )
    .filter((e) => e);
  return (
    <section className={`book-appoitment calendar`}>
      <div className="mb-0 contentainer-fluid">
        <form
          method="post"
          name="marcacaoConsulta"
          className="booking-form"
          onSubmit={(e) => submitForm(e)}
        >
          <input
            type="hidden"
            name="bookFor"
            id="bookFor"
            value={listFields.bookFor}
          />
          <label htmlFor="name">Como se Chama?</label>
          <input
            type="text"
            name="name"
            id="NAME"
            placeholder="Como se Chama?"
            onChange={handleChange}
            required
            className={errors["name"] ? "errorMsg" : ""}
          />

          <label htmlFor="firstTime">É a primeira sessão com {bookFor}?</label>
          <select
            name="firstTime"
            id="firstTime"
            onChange={(e) => {
              setFirstTime(e.target.value === "yes");
              handleChange(e);
            }}
            placeholder={`Primeira sessão ${bookFor}?`}
            required
            className={errors["firstTime"] ? "errorMsg" : ""}
          >
            <option value="no">Não</option>
            <option value="yes">Sim</option>
          </select>
          <label htmlFor="date">Para quando pretende?</label>
          <div className={errors["date"] ? "errorMsg" : ""}>
            <DatePicker
              selected={listFields.date}
              locale={el}
              onChange={(date) => {
                setListfields({
                  ...listFields,
                  date: date,
                });
                setErrors({ ...errors, date: undefined });
              }}
              inline
              includeDates={includeDates}
              placeholderText="Quando pretende a sua consulta?"
            />
          </div>
          <label htmlFor="hours">A que horas?</label>
          <select
            name="hours"
            id="hours"
            onChange={handleChange}
            disabled={!listFields.date}
            required
            className={errors["hours"] ? "errorMsg" : ""}
          >
            <option value="">A que horas?</option>
            {listFields.date &&
              dates[listFields.date.toLocaleDateString()] &&
              dates[listFields.date.toLocaleDateString()]
                .map((v) => {
                  let today = new Date();
                  let hour = moment(v, "H:mm");
                  let sameDay = listFields.date.toLocaleDateString() ===
                      today.toLocaleDateString() ;
                      
                  if ((sameDay && moment(today).add(2, 'hours').isBefore(hour)) || !sameDay) {
                    return (
                      <option key={v} value={v}>
                        {v}
                      </option>
                    );
                  }
                })
                .filter((e) => e)}
          </select>
          <label htmlFor="cEmail">Email</label>
          <input
            style={{ display: "none" }}
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            placeholder="O seu e-mail"
          />
          <input
            type="email"
            name="cEmail"
            id="cEmail"
            onChange={handleChange}
            placeholder="O seu e-mail"
            required
            className={errors["cEmail"] ? "errorMsg" : ""}
          />
          <label htmlFor="phoneNr">Telefone</label>
          <input
            type="text"
            name="phoneNr"
            id="phoneNr"
            placeholder="Telefone"
            onChange={handleChange}
            required
            className={errors["phoneNr"] ? "errorMsg" : ""}
          />
          <label htmlFor="notes">Notas (opcional)</label>
          <textarea
            type="text"
            name="notes"
            id="notes"
            placeholder="Pretende acrescentar alguma informação?"
            onChange={handleChange}
          />
          <label for="accept-terms">
            <div className="checkmark-container">
              <input required type="checkbox" name="accept-terms" />
              <span className="pl-2">
                Li e aceito a vossa{" "}
                <a href="./privacidade" target="_blank">
                  politica de privacidade
                </a>
              </span>
            </div>
          </label>
          <button
            type="submit"
            style={{ float: "right" }}
            disabled={success}
            className={`btn ${success ? "success-text-color" : "primary-bg"} button-em`}
          >
            {clicked ? (
              <>
                <span className="lds-dual-ring" /> A marcar...
              </>
            ) : success ? (
              <>
                <FontAwesomeIcon icon="check" className="white" /> Anotámos a
                sua marcação, iremos entrar em contacto brevemente para
                confirmar a marcação
              </>
            ) : (
              <>{config.cta}</>
            )}
          </button>
        </form>
      </div>
      <div className={`snackbar ${message.variant ? message.variant : ""}`}>
        {message.msg}
      </div>
    </section>
  );
}
