import React from "react";
import config from "../../config";

import Image from "./Image";

const Logo = ({ type = "normal" }) => {
  
  return (
    <a className="navbar-brand" href={`${config.url}${config.pathPrefix}`}>
      <Image
        alt={config.description}
        className={"logo"}
        name={
          type === "color" ? "logoColor.png" : "logo.png"
        }
      />
    </a>
  );
};

export default Logo;
