import React, { useState, useEffect } from "react";
import Scroll from "./Scroll";
import Logo from "./Logo";
import config from "../../config";
import data from "../../data";
import BookAppointment from "./BookAppointment";

const Header = ({ frontPage = true, setVisible=()=>{}, ...props }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [visibilityClass, setVisibilityClass] = useState(
    frontPage ? "" : "navbar-shrink"
  );
  const [active, setActive] = useState("");
  const [lastPosition, setLastPosition] = useState("");

  const toggleMenu = (value) => {
    setOpenMenu(value);
  };
  function checkVisible(elm, goingDown, threshold = 0) {
    if (elm) {
      let rect = elm.getBoundingClientRect();
      let viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      let above = rect.bottom - threshold < 0;
      let below = rect.top + threshold >= 0;

      return goingDown ? below : above;
    }
  }
  const handleScroll = function () {
    if (window.pageYOffset > 100 || !frontPage) {
      setVisibilityClass("navbar-shrink");
    } else {
      setVisibilityClass("");
    }
    let sel = "";
    data.sections.forEach((v) => {
      let element = document.getElementById(v.name);
      if (
        !sel &&
        checkVisible(
          element,
          window.pageYOffset > lastPosition,
          window.innerHeight / 2
        )
      ) {
        sel = v.name;
        return;
      }
    });
    setActive(sel);
    setVisible(sel);
    setLastPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass} ${data.BG}`}
      id="mainNav"
    >
      <div className="container">
        <Logo
          alt={config.description}
          className={"logo"}
          type={visibilityClass ? "color" : "normal"}
        />

        {frontPage && (
          <>
            <button
              onClick={(_) => toggleMenu(!openMenu)}
              className={`navbar-toggler navbar-toggler-right ${
                openMenu ? "" : "collapsed"
              }`}
              type="button"
              aria-controls="navbarResponsive"
              aria-expanded={openMenu}
              aria-label="Toggle navigation"
            >
              Menu
              <i className="fas fa-bars"></i>
            </button>
            <div
              className={`collapse navbar-collapse ${openMenu ? "show" : ""}`}
              id="navbarResponsive"
            >
              <ul className="navbar-nav ml-auto">
                {frontPage &&
                  data.sections
                    .map(
                      (v, i) =>
                        v.name && (
                          <li
                            title={v.description}
                            key={`menu-${i}`}
                            className={`nav-item`}
                          >
                            <Scroll
                              onClick={(_) => {
                                setVisible(v.name);
                                setActive(v.name);
                                toggleMenu(!openMenu);
                              }}
                              type="id"
                              element={v.name}
                            >
                              <a
                                className={`nav-link ${
                                  active === v.name ? "active" : ""
                                }`}
                                href={`#${v.name}`}
                              >
                                {v.name}
                              </a>
                            </Scroll>
                          </li>
                        )
                    )
                    .filter((e) => e)}
                <li className={`nav-item`}>
                  <BookAppointment title={config.cta} type="fg" />
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
