import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";
import config from "../../config";
import "../assets/sass/grayscale.scss";
require("typeface-source-sans-pro");
require("typeface-great-vibes");


const Layout = ({
  children,
  seoComp,
  seo = [],
  title,
  description = config.description,
  ...props
}) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.cookie = `from=${document.referrer}; path=/`;
    }
  }, []);
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          {seoComp || (
            <Helmet
              title={config.siteTitle + title || data.site.siteMetadata.title}
              meta={[
                ...[
                  { name: "description", content: description },
                  { name: "keywords", content: config.keywords },
                ],
                ...seo,
              ]}
            >
              <html lang="pt" />
            </Helmet>
          )}
          <h1
            style={{
              color: "transparent",
              display: "inline",
              position: "fixed",
              zIndex: "-1000",
              width: 0,
            }}
          >
            {config.description} -{config.keywords}
          </h1>
          <div className={"page-top"}>{children}</div>
          <CookieConsent
            location="bottom"
            buttonText="Aceito"
            className="cookieConsent"
            buttonClasses="button-em fg btn"
            disableButtonStyles={true}
            flipButtons={true}
            cookieName="cookie-concent"
          >
            Usamos cookies para lhe dar a melhor experiência de utilização. Ao
            continuar a utilizar o nosso site, assumimos que concorda com os
            termos :)
            <a
              style={{ color: "white", opacity: "0.8", marginLeft: "16px" }}
              rel="noreferrer"
              href="./privacidade/"
              target="_blank"
            >
              {" "}
              Politica Privacidade{" "}
            </a>
          </CookieConsent>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
